@import "../Utilities/variables.scss";
@import "../Utilities/mixins.scss";

.application-list-item{
    &:hover{
        background-color: #eeeded;
    }
    &__btn{
        &__item{
            display: inline-flex;
            align-items: center;
            
            padding: 5px 10px;

            @include border-radius;
            @include font-size(16);
            text-decoration: none;
            background-color: #fff;
            &--red{
                border: 2px solid $medium-danger;
                color: $medium-danger;
                &:hover{
                    transition:0.3s ease-out;
                    background-color: $medium-danger;
                    color: white;
                }
            }
            &--blue{
                margin-left: 10px;

                border: 2px solid $blue;
                color: $blue;
                &:hover{
                    transition:0.3s ease-out;
                    background-color: $blue;
                    color: white;
                }
            }
        }
    }
}