@import "../Utilities/variables.scss";
@import "../Utilities/mixins.scss";

.application-list{
    height: fit-content;
    width: 100%;
    background-color: white;
    &__content{
        padding: 0 30px;
        &__table{
            border-collapse: collapse;
            width: 100%;
            th,td{
                border: 1px solid $meduim-gray;
                text-align: center;
                padding: 8px;
            }
        }
        &__add{
            @include flex-center-center;

            height: 70px;
            margin-bottom: 20px;
            background-color: $light-gray;
            
            a{
                padding: 8px 10px;

                @include border-radius;
                color: black;
                background-color: $meduim-gray;
                &:hover{
                    background-color: $dark-gray;
                }
            }
        }
    }
}