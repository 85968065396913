@import "./variables.scss";

@mixin font-size($size, $base: 16) {
  font-size: $size *1px;
  font-size: ($size / $base) * 1rem;
}

@mixin success-notification{
  color:$high-success;
  background-color: #d1e7dd;
  border: 1px solid $low-success;
}

@mixin danger-notification{
  color: $hight-danger;
  background-color: #f8d7da;
  border: 1px solid $low-danger;
}

@mixin message {
  padding:15px 30px;
  border-radius: 5px;
  font-weight: bold;
}

@mixin  flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin border-radius($size:1) {
  border-radius: $size * 5px;
}
@mixin input{
  height: 40px;
  padding: 0 10px;
  margin-bottom: 10px;
  border: 1px solid $meduim-gray;
  @include font-size(14);
  @include border-radius ;
}