$linear-gradient:linear-gradient(30deg,#a445b2 , #fa4299);

$light-gray:#f0f0f0;
$meduim-gray:#dddddd;
$dark-gray:#d6d2d2;

$hight-danger:#842029;
$medium-danger:#cf3945;
$low-danger:#f5c2c7;

$high-success:#0f5132;
$medium-successs:#28a745;
$low-success:#badbcc;

$blue:#007bff;

