@import "../Utilities/variables.scss";
@import "../Utilities/mixins.scss";

.form-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    &__field{
        @include input;
    }
    &__btn{
        color: white;
        @include font-size(18);
        background: $linear-gradient;
    }
    
}