@import "../Utilities/variables.scss";
@import "../Utilities/mixins.scss";

.form{
    @include flex-center-center;
    flex-direction: column;

    height: 100vh;
    position: relative;
    background: $linear-gradient;
   &__wrapper{
    @include flex-center-center;
    flex-direction: column;

    width: 360px;
    padding: 30px;
    margin-bottom: 40px;
    
    background: white;
    border-radius: 10px;
    box-shadow: 0 15px 20px rgba(0,0,0,25%);

    &__selector{
        margin-bottom: 20px;
        button{
            @include font-size(18);
        }
        .active{
            background: $linear-gradient;
            color: white;
        }
    }
   }
}